export enum OperativeSystems {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
  Android = 'Android',
  iOS = 'iOS',
  Unknown = 'Unknown',
}

const getOS = (): OperativeSystems => {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;

    if (userAgent.includes('Win')) return OperativeSystems.Windows;
    if (userAgent.includes('Mac')) return OperativeSystems.MacOS;
    if (userAgent.includes('Linux')) return OperativeSystems.Linux;
    if (userAgent.includes('Android')) return OperativeSystems.Android;
    if (userAgent.includes('like Mac')) return OperativeSystems.iOS;
  }

  return OperativeSystems.Unknown;
};

export default getOS;
