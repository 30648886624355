import React, { FC, ReactNode, useEffect } from 'react';

import { AnalyticsProvider, useInitAnalytics } from 'reactfire';

import configuration from '~/configuration';
import { useAnalyticsTracking } from '~/core/firebase/hooks/use-analytics-tracking';
import { isBrowser } from '~/core/generic/is-browser';

interface FirebaseAnalyticsProviderProps {
  children: ReactNode;
  organizationId: Maybe<string>;
}

const FirebaseAnalyticsProvider: FC<FirebaseAnalyticsProviderProps> = ({
  children,
  organizationId,
}) => {
  const isEmulator = configuration.emulator;

  if (isEmulator || !isBrowser()) {
    return <>{children}</>;
  }

  return (
    <BrowserFirebaseAnalyticsProvider organizationId={organizationId}>
      {children}
    </BrowserFirebaseAnalyticsProvider>
  );
};

interface BrowserFirebaseAnalyticsProviderProps extends React.PropsWithChildren {
  organizationId: Maybe<string>;
}

function BrowserFirebaseAnalyticsProvider({
  organizationId,
  children,
}: BrowserFirebaseAnalyticsProviderProps) {
  const { data: sdk, status } = useInitAnalytics(async (app) => {
    const { getAnalytics } = await import('firebase/analytics');

    const analytics = getAnalytics(app);

    return analytics;
  });

  useEffect(() => {
    import('firebase/analytics').then((analytics) => {
      if (organizationId) {
        analytics.setUserProperties(analytics.getAnalytics(), { organization_id: organizationId });
      }
    });
  }, [organizationId]);

  if (status !== 'success') {
    return <>{children}</>;
  }

  return (
    <AnalyticsProvider sdk={sdk}>
      <AnalyticsTrackingEventsProvider>{children}</AnalyticsTrackingEventsProvider>
    </AnalyticsProvider>
  );
}

function AnalyticsTrackingEventsProvider({ children }: React.PropsWithChildren) {
  useAnalyticsTracking();

  return <>{children}</>;
}

export default FirebaseAnalyticsProvider;
